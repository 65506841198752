@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500&display=swap');

body {
  padding: 0;
  margin: 0;
  font-family: 'Outfit', sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* height: 100%; */
  overflow: auto;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-popover {
  color: var(--Dark-Gray-100, #2b3746);
  text-align: center;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  .ant-popconfirm-title {
    color: var(--Dark-Gray-100, #2b3746);
    text-align: center;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-popconfirm-buttons {
  }
}
.custom-model-mail {
  .ant-modal-content {
    padding: 0 !important; /* Remove all padding */
  }
}

.ant-picker-now-btn {
  color: var(--Dark-Gray-100, #2b3746);
  text-align: center;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ant-picker-now-btn:hover {
  color: var(--Dark-Gray-100, #2b3746);
  text-align: center;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-picker-ok {
  button {
    display: flex;
    height: 32px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    gap: 8px;
    border-radius: 3px;
    background: var(--Blue-100, #4cbbcb) !important;
    cursor: pointer;
    color: var(--White-100, #fff);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
}
.ant-picker-ok :hover :active button {
  display: flex;
  height: 32px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  gap: 8px;
  border-radius: 3px;
  background: var(--Blue-100, #4cbbcb) !important;
  cursor: pointer;
  color: var(--White-100, #fff);
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.ant-picker-time-panel-cell-selected {
  background: var(--Blue-20, rgba(76, 187, 203, 0.2));
}

.ant-popconfirm-buttons .ant-btn-default {
  border: 1px solid #d0dee1;
  height: 32px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  outline: none;
  gap: 8px;
  border-radius: 3px;
  background: white !important;
  cursor: pointer;
  color: var(--White-100, #fff);
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: var(--Gray-100, #556373);
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  width: 65px;
  font-weight: 400;
  line-height: 120%;
}
.ant-popconfirm-buttons .ant-btn-primary {
  height: 32px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  gap: 8px;
  border-radius: 3px;
  background: var(--Blue-100, #4cbbcb) !important;
  cursor: pointer;
  color: var(--White-100, #fff);
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.ant-select-selector:focus,
.ant-select-selector:hover,
.ant-select-selector {
  border-color: transparent !important;
  box-shadow: none !important;
}

.ant-select-selector:hover {
  background-color: transparent !important;
}

.ant-select-selector .ant-select-selection-search-input {
  border-color: transparent !important;
}
.ant-select-selector {
  padding: 0;
  /* height: 41px; */
}
.ant-select-selection-search {
  inset-inline-start: 12px !important;
  top: -5px !important;
}
.ant-select-selection-search input {
  height: auto;
  padding: 0;
  margin: 0;
}
.hide-cursor input {
  caret-color: transparent;
}
.custom-swal-button {
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #4cbbcb !important;
  border: none !important;
  /* width: 166px; */
  /* height: 42px; */
  height: unset;
  color: #556373 !important;
  font-family: Outfit !important;
  font-style: normal;
  font-weight: 400;
  box-shadow: none !important;

  /* align-self: stretch; */
  color: #fff !important;
}

.custom-swal-cancel-button {
  padding: 7px 12px;
  outline: none;
  border-radius: 4px;
  border: 1px solid #d0dee1 !important;
  background: #fff !important;
  cursor: pointer;
  color: var(--Dark-Gray-100, #2b3746) !important;
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.custom-swal-draft-button {
  padding: 7px 12px;
  border-radius: 4px;
  height: unset;
  color: #556373 !important;
  font-family: Outfit !important;
  font-style: normal;
  font-weight: 400;
  box-shadow: none !important;
  background-color: #dbf1f5 !important;
  border-radius: 8px;
  border-radius: 8px;
  border: 1px solid var(--Blue-100, #4cbbcb) !important;
  background: var(--Blue-20, rgba(76, 187, 203, 0.2));
  box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
  color: var(--Blue-100, #4cbbcb) !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--Blue-100, #4cbbcb) !important;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: 1px solid #4cbbcb !important;
}

.ant-picker-dropdown .ant-picker-header-view button:hover {
  color: #4cbbcb !important;
}

.ant-select-dropdown.mailbox-number-dropdown {
  width: 548px !important;
  inset: 505.65px auto auto 107.8px !important;
}

.ant-select-dropdown.mailbox-recipient-dropdown {
  width: 548px !important;
  inset: 582.65px auto auto 107.8px !important;
}
.ant-select-dropdown.mailbox-recipient-dropdown-sidebarCollapsed {
  width: 548px !important;
  inset: 582.65px auto auto 313.8px !important;
}
.ant-select-dropdown.mailbox-edit-number-dropdown {
  width: 548px !important;
  /* margin-top: 9px !important; */
  inset: 431.65px auto auto 107.8px !important;
}
.ant-select-dropdown.mailbox-edit-number-dropdown-sidebarCollapsed {
  width: 548px !important;
  /* margin-top: 9px !important; */
  inset: 505.65px auto auto 313.8px !important;
}
.ant-select-dropdown.mailbox-number-dropdown-sidebarCollapsed {
  width: 548px !important;
  /* margin-top: 9px !important; */
  inset: 505.65px auto auto 313.8px !important;
}
.ant-select-dropdown.location-forGroup-dropdown {
  width: 582px !important;
  inset: 257.65px auto auto 476px !important;
}
.ant-select-dropdown.mailbox-number-forGroup--dropdown {
  width: 582px !important;
  inset: 340.65px auto auto 476px !important;
}
.ant-select-dropdown.mailbox-number-forGroup-dropdown-sidebarCollapsed {
  width: 582px !important;
  inset: 340.65px auto auto 476px !important;
}
.select-carrie-dropdown {
  width: 548px !important;
  /* margin-top: 9px !important; */
  inset: 548.65px auto auto 107.8px !important;
}
.select-carrie-dropdown-sidebarCollapsed {
  width: 548px !important;
  /* margin-top: 9px !important; */
  inset: 548.65px auto auto 313.8px !important;
}
.select-package-dropdown {
  width: 548px !important;
  /* margin-top: 9px !important; */
  inset: 852.65px auto auto 106.8px !important;
}
.select-package-dropdown-sidebarCollapsed {
  width: 548px !important;
  /* margin-top: 9px !important; */
  inset: 852.65px auto auto 311.8px !important;
}
.ant-select-dropdown.select-loacton-dropdown {
  width: 548px !important;
  inset: 351.65px auto auto 105.8px !important;
  /* inset: 505.65px auto auto 107.8px !important; */
}
.ant-select-dropdown.select-loacton-edit-dropdown {
  width: 548px !important;
  inset: 276.65px auto auto 105.8px !important;
  /* inset: 505.65px auto auto 107.8px !important; */
}
.ant-select-dropdown.select-loacton-dropdown-sidebarCollapsed {
  width: 548px !important;
  inset: 351.65px auto auto 311.8px !important;
  /* inset: 505.65px auto auto 107.8px !important; */
}
.ant-select-dropdown.mailRoom-dropdown {
  width: 548px !important;
  inset: 814.65px auto auto 105.8px !important;
  /* inset: 505.65px auto auto 107.8px !important; */
}
.ant-select-dropdown.mailRoom-dropdown-sidebarCollapsed {
  width: 548px !important;
  inset: 814.65px auto auto 311.8px !important;
  /* inset: 505.65px auto auto 107.8px !important; */
}
.ant-select-dropdown.employee-location-dropdown {
  width: 548px !important;
  inset: 612.65px auto auto 105.8px !important;
  /* inset: 505.65px auto auto 107.8px !important; */
}
.ant-select-dropdown.employee-location-dropdown-sidebarCollapsed {
  width: 548px !important;
  inset: 612.65px auto auto 311.8px !important;
  /* inset: 505.65px auto auto 107.8px !important; */
}
.ant-select-dropdown.mailExport-dropdown {
  width: 548px !important;
  inset: 1232.65px auto auto 105.8px !important;
}
.ant-select-dropdown.mailExport-dropdown-sidebarCollapsed {
  width: 548px !important;
  inset: 1232.65px auto auto 311.8px !important;
}
.ant-select-dropdown.mailorign1-dropdown {
  width: 445px !important;
  inset: 1352.65px auto auto 950.8px !important;
}
.ant-select-dropdown.mailorign1-dropdown-sidebarCollapsed {
  width: 340px !important;
  inset: 1353.65px auto auto 1052.8px !important;
}
.ant-select-dropdown.mailType-dropdown {
  width: 440px !important;
  inset: 738.65px auto auto 105.8px !important;
  /* inset: 505.65px auto auto 107.8px !important; */
}
.ant-select-dropdown.mailType-dropdown-sidebarCollapsed {
  width: 440px !important;
  inset: 738.65px auto auto 311.8px !important;
  /* inset: 505.65px auto auto 107.8px !important; */
}
.ant-select-dropdown.select-loacton-dropdown-edit-sidebarCollapsed {
  width: 548px !important;
  inset: 276.65px auto auto 311.8px !important;
  /* inset: 505.65px auto auto 107.8px !important; */
}
.ant-select-dropdown.select-access-dropdown {
  /* width: 536px !important; */
  /* inset: 1113.65px auto auto 184.8px !important; */
  /* inset: 505.65px auto auto 107.8px !important; */
  margin-top: 10px;
}
.ant-select-dropdown.message-loacton-dropdown {
  width: 548px !important;
  /* inset: 968.65px auto auto 105.8px !important; */
  inset: 970.65px auto auto 105.8px !important;
}
.ant-select-dropdown.message-loacton-dropdown-extended {
  width: 548px !important;
  inset: 1045.65px auto auto 105.8px !important;
  /* inset: 891.65px auto auto 105.8px !important; */
}
.ant-select-dropdown.message-loacton-dropdown-sidebarCollapsed-extended {
  width: 548px !important;
  inset: 1045.65px auto auto 311.8px !important;
}
.ant-select-dropdown.message-loacton-dropdown-sidebarCollapsed {
  width: 548px !important;
  /* inset: 968.65px auto auto 311.8px !important; */
  inset: 968.65px auto auto 311.8px !important;
}
.companyDataPopUp {
  z-index: 2 !important;
  .ant-select-item {
    display: flex;
    padding: 0px 14px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: var(--Gray-100, #556373);
    font-family: Outfit;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-select-item-option-active {
    background-color: #dbf1f5 !important;
  }
}
.smooth-dnd-container {
  height: 100% !important;
}
.custom-input input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.custom-input label {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 16px; /* Adjust the width to match your checkbox size */
  height: 16px; /* Adjust the height to match your checkbox size */
}

.custom-input label:before {
  content: '';
  background-color: transparent;
  border: 2px solid #4cbbcb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  margin-bottom: 4px;
  box-sizing: border-box; /* Ensures padding/border doesn't affect the size */
}

.custom-input input:checked + label:after {
  content: '';
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg); /* Center the checkmark */
  width: 4px; /* Adjust the width of the checkmark */
  height: 8px; /* Adjust the height of the checkmark */
  border: solid #4cbbcb;
  border-width: 0 2.5px 2.5px 0;
}

.custom-input input:checked + label:before {
  background-color: #fff; /* Optional: fill in the background when checked */
}
.common-btn {
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Blue-100, #4cbbcb);
  color: var(--White-100, #fff);
  text-align: center;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
  cursor: pointer;
  height: 30px;
}
.common-font {
  color: var(--Gray-70, rgba(85, 99, 115, 0.7));
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 177.778% */
  margin-right: 5px;
}

@media print {
  body * {
    visibility: hidden !important;
  }

  #printable-area,
  #printable-area * {
    visibility: visible !important;
  }
  .ant-layout {
    margin-left: 0 !important;
  }
  .ant-layout-sider {
    display: none !important;
  }
  .sticky-header,
  [style*='position: sticky'],
  [style*='position: fixed'] {
    position: static !important;
  }

  #printable-area {
    position: absolute !important;
    top: -318px;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    height: auto !important;
    overflow: visible !important;
    page-break-inside: auto;
    margin-top: -176px !important;
  }

  .truncatedTextOne,
  .truncatedTextTwo,
  .table-footer,
  .tabel-header-hide {
    display: none !important;
  }

  .unTrunctaedText {
    display: block !important;
  }

  tr {
    page-break-inside: avoid !important;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    table-layout: auto; /* Use auto to ensure proper handling of table structure */
  }

  thead {
    display: table-header-group; /* Ensure header repeats on each page */
  }

  tbody {
    display: table-row-group;
  }

  tfoot {
    display: table-footer-group; /* Footer appears at the end of each page */
  }

  td,
  th {
    word-break: break-word; /* Prevent long text from breaking layout */
  }

  /* Ensure each row can flow to the next page */
  tr {
    page-break-inside: avoid !important;
  }

  /* Add space between pages */
  #printable-area tr:last-of-type {
    page-break-after: always;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    page-break-inside: auto;
    .heading-row {
      position: initial !important;
      display: flex;
      align-items: flex-start;
      width: 100%;
      align-self: stretch;
      border-radius: 4px;
      border-bottom: 1px solid #e3eced;
      padding: 0px 32px 0px 18px;

      .sort-icons {
        display: flex;
        flex-direction: column;
        padding-left: 2px;
        justify-content: center;
        align-items: center;
        /* padding: 20px; */
        :last-child svg {
          position: absolute;
          height: 15px;
          margin-top: 4px;
        }
      }
      .btns-div {
        display: flex;
        gap: 8px;
        padding: 3px 11px;
        .add-btn {
          display: flex;
          padding: 6px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          background: var(--Blue-100, #4cbbcb);
          color: var(--White-100, #fff);
          text-align: center;
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          border: none;
          cursor: pointer;
          height: 34px;
        }
      }
      .title {
        color: var(--Gray-70, rgba(85, 99, 115, 0.7));
        font-family: Outfit;
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 177.778% */
        text-transform: uppercase;
      }
      .cursor {
        cursor: pointer;
      }
      .checkbox {
        display: none !important;
        max-width: 40px;
        width: 100%;
        height: 40px;
        display: flex;
        padding: 20px 18px 12px 0px;
        border-radius: 4px 0px 0px 4px;
        align-items: center;
        gap: 10px;
        .count {
          display: flex;
          flex: none;
          font-family: Outfit;
          font-size: 14px;
          margin-bottom: 9px;
          color: var(--Gray-100, #556373);
          font-weight: 400;
        }
        .custom-input {
          flex: none;
        }
        .custom-input input {
          padding: 0;
          height: initial;
          width: initial;
          margin-bottom: 0;
          display: none;
          cursor: pointer;
        }
        .custom-input label {
          position: relative;
          cursor: pointer;
        }
      }

      .id {
        display: flex;
        width: 100%;
        max-width: 85px !important;
        padding: 12px;
        align-items: center;
        .status-sorticon {
          position: absolute;
          margin-left: 16px;
          justify-content: center;
          align-self: center;
          align-items: center;
          display: flex;
        }
      }

      .mailbox-id {
        display: flex;
        max-width: 105px;
        width: 100%;
        padding: 12px;
        align-items: center;
        /* flex: 1 0 0; */
        justify-content: center;
        .status-sorticon {
          position: absolute;
          margin-left: 69px;
          justify-content: center;
          align-self: center;
          align-items: center;
          display: flex;
        }
      }

      .sender {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        max-width: 300px !important;
        width: 100%;
        min-width: 126px;
      }

      .date {
        display: flex;
        max-width: '140px';
        width: 100%;
        padding: 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        min-width: 130px;
      }

      .type {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        max-width: 80px;
        /* width: 100%; */
      }

      .QTY {
        display: flex;
        /* width: 100%; */
        max-width: 50px;
        min-width: 80px;
        padding: 12px;
        align-items: center;
        gap: 10px;
        justify-content: center;
        /* padding-right: 38px; */
        .status-sorticon {
          position: absolute;
          margin-left: 48px;
          justify-content: center;
          align-self: center;
          align-items: center;
          display: flex;
        }
      }

      .MRL {
        display: flex;
        width: 100%;
        max-width: 100px;
        min-width: 94px;
        padding: 12px;
        align-items: center;
        gap: 10px;
        justify-content: center;
        /* padding-right: 38px; */
        .status-sorticon {
          position: absolute;
          margin-left: 48px;
          justify-content: center;
          align-self: center;
          align-items: center;
          display: flex;
        }
      }
      .status {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        max-width: 200px;
        /* height: 48px; */
        width: 100%;
        justify-content: center;
        /* padding-right: ${(props) => (props.sidebarCollapsed ? '' : '24px')}; */
        .status-sorticon {
          position: absolute;
          margin-left: 58px;
          justify-content: center;
          align-self: center;
          align-items: center;
          display: flex;
        }
      }

      .actions {
        display: none !important;
        max-width: 240px;
        width: 100%;
        padding: 12px;
        align-items: center;
        gap: 10px;
        /* border: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05)); */
      }
      .action-collpassed {
        max-width: 40px;
      }
    }

    .active-cls {
      border-radius: 8px;
      outline: 1px solid var(--Blue-100, #4cbbcb);
      background: var(--Blue-20, rgba(76, 187, 203, 0.2));
      box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
    }

    .td-heading-border {
      border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
    }

    .td-active-border {
      border-right: 1px solid var(--Blue-30, rgba(76, 187, 203, 0.3)) !important;
    }

    .td-heading {
      display: flex;
      align-items: flex-start;
      align-self: stretch;
      width: 100%;
      height: 48px;
      border-bottom: 1px solid #e3eced;
      padding: 0px 32px 0px 18px !important;

      .td-title {
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      .td-checkbox {
        display: none !important;
        max-width: 40px;
        width: 100%;
        padding: 16px 18px 16px 0px;
        align-items: flex-start;
        gap: 10px;
        .custom-input input {
          padding: 0;
          height: initial;
          width: initial;
          margin-bottom: 0;
          display: none;
          cursor: pointer;
        }

        .custom-input label {
          position: relative;
          cursor: pointer;
        }
      }

      .td-id {
        display: flex;
        width: 100%;
        max-width: 85px !important;
        padding: 16px 12px;
        align-items: center;
        gap: 10px;
        height: 48px;
      }

      .td-mailbox-id {
        display: flex;
        max-width: 105px;
        width: 100%;
        padding: 16px 12px;
        align-items: center;
        height: 48px;
        justify-content: center;
        gap: 10px;
      }

      .td-sender {
        display: flex;
        padding: 16px 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        max-width: 300px !important;
        height: 48px;
        width: 100%;
      }

      .td-send-date {
        display: flex;
        padding: 16px 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        max-width: 140px;
        width: 100%;
        max-width: '140px';
        height: 48px;
      }

      .td-type {
        display: flex;
        padding: 16px 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;

        max-width: 80px;
        /* width: 100%; */
        height: 48px;
      }
      .td-QTY {
        display: flex;
        padding: 16px 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        max-width: 50px;
        height: 48px;
        justify-content: center;
      }

      .td-status {
        display: flex;
        padding: 16px 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        max-width: 200px;
        height: 48px;
        width: 100%;
        justify-content: center;

        span {
          display: flex;
          padding: 6px 12px;
          align-items: center;
          gap: 10px;
          border: none !important;
          border-radius: 4px;
          background: rgba(76, 203, 96, 0.2) !important;
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          height: 32px;
          max-width: 10rem;
          justify-content: center;
          width: 100%;
          text-align: center;
        }
      }
      .bg-white span {
        background: rgba(255, 255, 255, 0.2) !important;
      }
      .td-action {
        display: none !important;
        max-width: 240px;
        width: 100%;
        padding: 16px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 48px;
        .icons-wrapper {
          display: flex;
          max-width: 288px;
          width: 100%;
          gap: 10px;
          align-items: center;
          flex-shrink: 0;
          align-self: stretch;
          span:hover {
            cursor: pointer;
            svg path {
              fill: #2b3746;
            }
          }
        }
      }
      .td-action-collpassed {
        max-width: 40px;
        display: none !important;
      }
    }
    .hovered {
      background-color: #dbf1f5;
      border-radius: 8px;
      border-radius: 8px;
      /* border: 1px solid var(--Blue-100, #4cbbcb); */
      background: var(--Blue-20, rgba(76, 187, 203, 0.2));
      box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
    }
    .add-btn {
      display: flex;
      max-width: 1229px;
      height: 56px;
      padding: 12px;
      align-items: center;
      gap: 10px;
      border-radius: 0px 0px 8px 8px;
      background: transparent;
      border: none;

      .btn-text {
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;
      }

      .btn-icon {
        width: 16px;
        height: 16px;
        svg {
          width: 16px;
          height: 16px;
          fill: #4cbbcb;
        }
      }
    }
    .pagination-container {
      display: flex;
      width: 100%;
    }
    .table-footer {
      display: flex;
      height: 56px !important;
      padding: 12px 32px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: relative;
      border-radius: 0px 0px 8px 8px;
      border: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
      background: var(--Gray-2, rgba(85, 99, 115, 0.02));
    }
  }
}
.pop-over-custom {
  margin-left: 8px;
  .ant-popover-inner {
    padding: 0 !important;
  }
}
.custom-drawer {
  .ant-drawer-header {
    background-color: white;
  }

  .ant-drawer-header-title {
    width: 100%;
  }
  .ant-drawer-header-title {
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
  }
  .ant-drawer-header-title .ant-drawer-close {
    /* padding-inline-end: 16px; */
    line-height: 0;
    padding: 0;
    margin: 0;
    width: 32px;
    height: 32px;
    padding-top: 1px;
  }
  .ant-drawer-body {
    padding: 24px 32px 32px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;
  }
}
.ant-select-item-option-content {
  font-family: 'Outfit' !important;
  color: var(--Gray-100, #556373);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ticket-custom-tooltip {
  .ant-tooltip-content {
    position: absolute;
    margin-top: -5px;
    width: max-content;
    margin-left: -3.5rem;
  }
  .ant-tooltip-inner {
    cursor: pointer;
    border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
    background-color: #fff;
    color: #556373;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 4px;
    padding: 8px 12px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    height: 32px;
  }
}
.ticket-custom-tooltip-for-select {
  .ant-tooltip-content {
    position: absolute;
    margin-top: -5px;
    width: max-content;
    margin-left: -2.3rem;
  }
  .ant-tooltip-inner {
    cursor: pointer;
    border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
    background-color: #fff;
    color: #556373;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 4px;
    padding: 8px 12px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    height: 32px;
  }
}
.no-hover {
  pointer-events: none;
}
